import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import GUI from 'lil-gui'

/**
 * Base
 */
// Debug
// const gui = new GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const transTexture = textureLoader.load('/textures/matcaps/trans.png')
transTexture.colorSpace = THREE.SRGBColorSpace

const matcapTexture = textureLoader.load('/textures/matcaps/1.png')

/**
 * Fonts
 */
const fontLoader = new FontLoader()

fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) =>
    {
        // Material
        const material = new THREE.MeshMatcapMaterial({ matcap: transTexture })

        // Text
        const textGeometry = new TextGeometry(
            'Trans is beautiful',
            {
                font: font,
                size: 0.5,
                depth: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5
            }
        )
        textGeometry.center()

        const text = new THREE.Mesh(textGeometry, material)
        scene.add(text)


    }
)

//Trans materials
const enumMats =[
new THREE.MeshToonMaterial({
    color: 0xffffff
}),
new THREE.MeshToonMaterial({
    color: 0xf5a9b8,
}),
new THREE.MeshToonMaterial({
    color: 0x5bcefa,
})
]
const transMat = new THREE.MeshMatcapMaterial({ matcap: transTexture })

const x = 0, y = 0;
    const heartShape = new THREE.Shape();

    heartShape.moveTo(x + 2.5, y + 2.5);
    heartShape.bezierCurveTo(x + 2.5, y + 2.5, x + 2, y, x, y);
    heartShape.bezierCurveTo(x - 3, y, x - 3, y + 3.5, x - 3, y + 3.5);
    heartShape.bezierCurveTo(x - 3, y + 5.5, x - 1, y + 7.7, x + 2.5, y + 9.5);
    heartShape.bezierCurveTo(x + 6, y + 7.7, x + 8, y + 5.5, x + 8, y + 3.5);
    heartShape.bezierCurveTo(x + 8, y + 3.5, x + 8, y, x + 5, y);
    heartShape.bezierCurveTo(x + 3.5, y, x + 2.5, y + 2.5, x + 2.5, y + 2.5);

const extrudeSettings = {
 depth: 1,
 bevelEnabled: true,
 bevelSegments: 2,
 steps: 2,
 bevelSize: 1,
 bevelThickness: 1,
};

const heartGeometry = new THREE.ExtrudeGeometry(heartShape, extrudeSettings);
const heartArray = []
for(let i = 0; i < 100; i++)
{
    const index = i % 3 
    const heart = new THREE.Mesh(heartGeometry, enumMats[index])
    heart.position.x = (Math.random() - 0.5) * 10
    heart.position.y = (Math.random() - 0.5) * 10
    heart.position.z = (Math.random() - 0.5) * 10

    heart.rotation.x = Math.random() * Math.PI
    heart.rotation.y = Math.random() * Math.PI
    heart.rotation.z = Math.random() * Math.PI

    const factor = Math.random()
    heart.scale.x = factor * 0.05
    heart.scale.y = factor * 0.05
    heart.scale.z = factor * 0.05
    heartArray.push(heart)
    scene.add(heart)
}


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = -3
camera.position.y = -2.5
camera.position.z = 6.3
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

// gui.add(camera.position, 'x',-10,10,0.01);
// gui.add(camera.position, 'y',-10,10,0.01);
// gui.add(camera.position, 'z',-10,10,0.01);

//Light

const directionalLight = new THREE.DirectionalLight(0xfffffff, 1)
directionalLight.position.z = 10
scene.add(directionalLight)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)
    heartArray.forEach(function(heart,idx){
        const sign = idx % 2 == 0 ? 1: -1;
        heart.rotation.x += 0.05 * sign
        heart.rotation.y += 0.05 * sign
    });
    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()